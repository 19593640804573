import React, { useState, useEffect, useContext } from "react";
import colors from "../config/colors";
import EmailSubmissionButton from "./EmailSubmissionButton";
import { LanguageContext } from "../contexts/LanguageContext";
import { GrLanguage } from "react-icons/gr";

const NavBar = ({ isButtonInNavbar }) => {
  const { translations, changeLanguage, language } = useContext(LanguageContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getButtonLeftValue = () => {
    if (windowWidth <= 1300) return "6rem";
    if (windowWidth <= 1450) return "6rem";
    if (windowWidth <= 1600) return "8rem";
    if (windowWidth <= 1700) return "12rem";
    if (windowWidth <= 1900) return "17rem";
    return "30.5rem";
  };

  const getButtonStyle = () => {
    if (windowWidth < 1190) {
      return {
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
      };
    }
    return {
      position: "absolute",
      left: getButtonLeftValue(),
    };
  };

  const menuItemStyle = {
    fontWeight: "550",
    marginRight: "20px",
    textDecoration: "none",
    color: colors.primary,
    cursor: "pointer",
  };

  const languageOptions = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'fr', name: 'Français' },
    { code: 'pt', name: 'Português' },
  ];

  const handleLanguageChange = (langCode) => {
    changeLanguage(langCode);
    setIsLanguageMenuOpen(false);
  };

  const renderLanguageToggle = () => (
    <div style={{ position: 'relative'}}>
      <button
        onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
        style={{
          ...menuItemStyle,
          background: 'none',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          marginRight: "-7px", // Remove the right margin from menuItemStyle
        }}
      >
        <GrLanguage size={26} color={colors.highlight} />
      </button>
      {isLanguageMenuOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            right: 0,
            backgroundColor: colors.background,
            border: `1px solid ${colors.primary}`,
            borderRadius: '5px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            zIndex: 1000,
          }}
        >
          {languageOptions.map((lang) => (
            <button
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              style={{
                display: 'block',
                width: '100%',
                padding: '10px 20px',
                textAlign: 'left',
                border: 'none',
                background: 'none',
                color: colors.primary,
                cursor: 'pointer',
              }}
            >
              {lang.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const navPadding = "20px";

  return (
    <nav
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: "60px",
        backgroundColor: `${colors.background}80`,
        backdropFilter: "blur(5px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: `0 ${navPadding}`,
        zIndex: 1000,
      }}
    >
      {isButtonInNavbar && (
        <div
          style={{
            transition:
              "opacity 0.3s ease-in-out, left 0.3s ease-in-out, transform 0.3s ease-in-out",
            opacity: isButtonInNavbar ? 1 : 0,
            ...getButtonStyle(),
          }}
        >
          <EmailSubmissionButton 
            translations={translations} 
            color={colors.buttonPrimary} 
            textColor="white"
            language={language}
            openStyle="nav"
          />
        </div>
      )}
      <div
        style={{
          fontFamily: "'Slabo 13px', serif",
          fontWeight: "bold",
          fontSize: "1.5rem",
          color: colors.primary,
        }}
      >
        {translations?.navbar?.logo || "Aly"}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {renderLanguageToggle()}
      </div>
    </nav>
  );
};

export default NavBar;