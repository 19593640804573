// src/components/EmailSubmissionButton.js
import React, { useState, useEffect } from 'react';

const GOOGLE_FORM_ACTION_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdwHhD5vIiOknDtrXxuu-s84O4QEwb6Ew1-kPXoZlclTylq5Q/formResponse';
const GOOGLE_FORM_EMAIL_ID = 'entry.463453782';

const EmailSubmissionButton = ({ translations, textColor = '#ffffff', language, openStyle="inline"}) => {
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState({ type: '', message: '' });
  const [submittedEmails, setSubmittedEmails] = useState([]);
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  useEffect(() => {
    // Load submitted emails from localStorage on component mount
    const storedEmails = JSON.parse(localStorage.getItem('submittedEmails')) || [];
    setSubmittedEmails(storedEmails);
  }, []);

  useEffect(() => {
    if (status.message && openStyle === 'nav') {
      setIsMessageVisible(true);
      const timer = setTimeout(() => {
        setIsMessageVisible(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [status, openStyle]);

  const buttonStyle = {
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: textColor,
    backgroundColor: "#3498db",
    border: 'none',
    borderRadius: '25px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  };

  const fullScreenMessageStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 9999,
    color: '#ffffff',
    fontSize: '24px',
    textAlign: 'center',
    padding: '20px',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for duplicate email
    if (submittedEmails.includes(email.trim().toLowerCase())) {
      setStatus({ type: 'error', message: translations?.emailSubmission?.duplicateMessage || 'This email has already been submitted.' });
      return;
    }

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append(GOOGLE_FORM_EMAIL_ID, `${email}~~~${language}`);
      const response = await fetch(GOOGLE_FORM_ACTION_URL, {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      });

      if (response.type === 'opaque') {
        setEmail('');
        setStatus({ type: 'success', message: translations?.emailSubmission?.successMessage || 'Thanks for joining our waitlist!' });

        // Store the submitted email in localStorage
        const updatedEmails = [...submittedEmails, email.trim().toLowerCase()];
        setSubmittedEmails(updatedEmails);
        localStorage.setItem('submittedEmails', JSON.stringify(updatedEmails));

        // Fire Google Analytics conversion event
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-16702398105/1mzvCL6n4dIZEJm9qZw-',
            'value': 1.0,
            'currency': 'CAD'
          });
        } else {
          console.warn('gtag function is not available');
        }
      } else {
        throw new Error('Unexpected response');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus({ type: 'error', message: translations?.emailSubmission?.errorMessage || 'Failed to join waitlist. Please try again later.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {!isInputVisible ? (
        <button
          style={buttonStyle}
          onClick={() => setIsInputVisible(true)}
        >
          {translations?.buttons?.joinWaitlist || "Join Waitlist"}
        </button>
      ) : (
        <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="email"
            placeholder={translations?.emailSubmission?.placeholder || "Enter your email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isSubmitting}
            style={{
              padding: '12px 20px',
              fontWeight: 'bold',
              borderRadius: '5px',
              marginRight: '10px',
              fontSize: '16px',
              width: '200px',
              border: "none"
            }}
          />
          <button
            type="submit"
            disabled={isSubmitting}
            style={{
              ...buttonStyle,
              padding: '10px 15px',
              fontSize: '14px',
            }}
          >
            {isSubmitting ? (translations?.emailSubmission?.joiningText || 'Joining...') : (translations?.emailSubmission?.submitText || 'Submit')}
          </button>
        </form>
      )}
      {status.message && openStyle === 'nav' && isMessageVisible && (
        <div style={{
          ...fullScreenMessageStyle,
          opacity: isMessageVisible ? 1 : 0,
          transition: 'opacity 0.5s ease-out',
        }}>
          {status.message}
        </div>
      )}
      {status.message && openStyle !== 'nav' && (
        <div style={{
          marginTop: '10px',
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: status.type === 'success' ? '#d4edda' : '#f8d7da',
          color: status.type === 'success' ? '#155724' : '#721c24',
        }}>
          {status.message}
        </div>
      )}
    </div>
  );
};

export default EmailSubmissionButton;